var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.editMode,
          expression: "!editMode",
        },
      ],
      class: { hasSchemaViewerData: _vm.hasSchemaViewerData },
      attrs: { id: "schema-form-data-viewer" },
    },
    [
      _vm.disclaimer.length
        ? _c(
            "div",
            {
              staticClass: "alert alert-info",
              class: _vm.schemaViewerData ? "text-left" : "text-center",
              attrs: { role: "alert" },
            },
            [_c("b", [_vm._v(_vm._s(_vm.disclaimer))])]
          )
        : _vm._e(),
      !_vm.loading
        ? [
            _vm.headerText || !_vm.hasSchemaViewerData
              ? _c("div", { staticClass: "schema-form-data-viewer-top" }, [
                  _vm.headerText
                    ? _c("h5", [
                        _vm._v(
                          "\n        " + _vm._s(_vm.headerText) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                  !_vm.hasSchemaViewerData
                    ? _c("p", { domProps: { innerHTML: _vm._s(_vm.subTitle) } })
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "schema-form-data-viewer-bottom" }, [
              _c("div", { staticClass: "viewer__data" }, [
                _c(
                  "div",
                  { class: !_vm.hasSchemaViewerData ? "row" : "" },
                  _vm._l(_vm.nonBooleanFormData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: `${item.id}${index}`,
                        staticClass: "display-content-margin w-100",
                      },
                      [
                        _vm.dataType(index, item) === "string"
                          ? _c("div", [
                              _c("h6", { staticClass: "margin-left-1-75" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.displayKey(index)) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", { staticClass: "margin-left-1-75" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item) +
                                    "\n              "
                                ),
                              ]),
                            ])
                          : _vm.dataType(index, item) === "registered-agent"
                          ? _c(
                              "div",
                              {
                                staticClass: "privacy-level pb-2",
                                class: _vm.highlightClasses(
                                  index,
                                  item.address
                                ),
                              },
                              [
                                _c("h6", [
                                  _vm._v(
                                    "\n                Registered Agent\n              "
                                  ),
                                ]),
                                _vm.usingRAWhenNotHired(item) &&
                                !_vm.hasSchemaViewerData
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "invalid-data-link",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToSlide(
                                                "form_the_company__fill_out_form__pdf_viewer"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Invalid/Missing Data\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      item.name
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-weight-bold px-4",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.name) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.contact_first_name ||
                                      item.contact_last_name ||
                                      item.title
                                        ? _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-weight-bold px-4",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      item?.contact_first_name
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      item?.contact_last_name
                                                    ) +
                                                    "\n                    "
                                                ),
                                                item.title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                      - " +
                                                          _vm._s(item.title) +
                                                          "\n                    "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.signature
                                        ? _c("img", {
                                            ref: item.name,
                                            refInFor: true,
                                            staticClass: "half-width-max",
                                            attrs: {
                                              src: item.signature,
                                              alt: "signature",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.isAddress(item.address)
                                        ? _c("order-item-address", {
                                            staticClass: "px-4",
                                            attrs: {
                                              address: item.address,
                                              attention: item.attention || "",
                                              "address-on-one-line": true,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            )
                          : _vm.dataType(index, item) === "person"
                          ? _c("div", { staticClass: "display-contents" }, [
                              _vm.showOfficialArray(index, item)
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "h6",
                                        { staticClass: "margin-left-1-75" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.officialRoleHeader(item)
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        item,
                                        function (official, officialIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: `${official.role}${officialIndex}`,
                                            },
                                            [
                                              _vm.isOfficial(official)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "privacy-level mb-1 py-2",
                                                      class:
                                                        _vm.highlightClasses(
                                                          index,
                                                          official?.address
                                                        ),
                                                    },
                                                    [
                                                      official?.first_name ||
                                                      official?.last_name
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold px-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    official?.first_name
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    official?.last_name
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      official?.company_name
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "font-weight-bold px-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    official.company_name
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.showAdditionalOfficialData &&
                                                      official?.phone_number
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "px-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.displayPhoneNumber(
                                                                      official.phone_number
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.showAdditionalOfficialData &&
                                                      official?.email_address
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "px-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    official.email_address
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.isAddress(
                                                        official.address
                                                      )
                                                        ? _c(
                                                            "order-item-address",
                                                            {
                                                              staticClass:
                                                                "px-4",
                                                              attrs: {
                                                                address:
                                                                  official.address,
                                                                attention:
                                                                  item.attention ||
                                                                  "",
                                                                "address-on-one-line": true,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm.showAdditionalOfficialData &&
                                                      official?.company_ownership_percentage >
                                                        0
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "px-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      Ownership Percentage: " +
                                                                  _vm._s(
                                                                    official.company_ownership_percentage
                                                                  ) +
                                                                  "%\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm.isOfficial(item) ||
                                  index === "filer" ||
                                  index.includes("official.")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "privacy-level pb-2",
                                      class: _vm.highlightClasses(
                                        index,
                                        item?.address
                                      ),
                                    },
                                    [
                                      _c("h6", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.displayOfficialRole(
                                                item,
                                                index
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                      Array.isArray(item) && item.length === 0
                                        ? _c(
                                            "p",
                                            { staticClass: "margin-left-1-75" },
                                            [
                                              _vm._v(
                                                "\n                  -\n                "
                                              ),
                                            ]
                                          )
                                        : [
                                            item.first_name || item.last_name
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold px-4",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item?.first_name
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          item?.last_name
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item?.name
                                              ? _c(
                                                  "p",
                                                  { staticClass: "px-4" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(item.name) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item?.company_name
                                              ? _c(
                                                  "p",
                                                  { staticClass: "px-4" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.company_name
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.showAdditionalOfficialData &&
                                            item?.email_address
                                              ? _c(
                                                  "p",
                                                  { staticClass: "px-4" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.email_address
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.showAdditionalOfficialData &&
                                            item?.phone_number
                                              ? _c(
                                                  "p",
                                                  { staticClass: "px-4" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.displayPhoneNumber(
                                                            item.phone_number
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isAddress(item.address)
                                              ? _c("order-item-address", {
                                                  staticClass: "px-4",
                                                  attrs: {
                                                    address: item.address,
                                                    attention:
                                                      item.attention || "",
                                                    "address-on-one-line": true,
                                                  },
                                                })
                                              : _vm.isString(item?.address)
                                              ? _c("p", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.address) +
                                                      "\n                  "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.showAdditionalOfficialData &&
                                            item?.company_ownership_percentage >
                                              0
                                              ? _c(
                                                  "p",
                                                  { staticClass: "px-4" },
                                                  [
                                                    _vm._v(
                                                      "\n                    Ownership Percentage: " +
                                                        _vm._s(
                                                          item.company_ownership_percentage
                                                        ) +
                                                        "%\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ])
                          : _vm.dataType(index, item) === "address"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "privacy-level margin-left-1-75 display-content-margin",
                                class: _vm.highlightClasses(index, item),
                              },
                              [
                                _c("h6", { staticClass: "m-0" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.displayKey(index)) +
                                      "\n              "
                                  ),
                                ]),
                                _vm.usingRAAddressWhenNotHired(item) &&
                                !_vm.hasSchemaViewerData
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "invalid-data-link",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToSlide(
                                                "form_the_company__fill_out_form__pdf_viewer"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Invalid/Missing Data\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _vm.isAddress(item)
                                        ? _c("order-item-address", {
                                            staticClass: "privacy-details",
                                            attrs: {
                                              address: item,
                                              attention: item.attention || "",
                                              "address-on-one-line": true,
                                            },
                                          })
                                        : _vm.isString(item?.address)
                                        ? _c(
                                            "p",
                                            { staticClass: "privacy-details" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.address) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            )
                          : _vm.dataType(null, item) === "object"
                          ? _c(
                              "div",
                              { staticClass: "margin-left-1-75" },
                              [
                                _c("h6", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.displayKey(index)) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._l(item, function (itemValue, itemIndex) {
                                  return _c(
                                    "div",
                                    { key: `${itemValue}${itemIndex}` },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(itemValue) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { class: !_vm.hasSchemaViewerData ? "row" : "" },
                  _vm._l(_vm.booleanFormData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: `${item.id}${index}`,
                        staticClass: "display-content-margin w-100",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 mb-1" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "float-left",
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.booleanFormData[index],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.booleanFormData, index, $$v)
                                  },
                                  expression: "booleanFormData[index]",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.displayKey(index)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "viewer__icon-legend" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAnyUnprotected,
                        expression: "isAnyUnprotected",
                      },
                    ],
                    staticClass: "unprotected-address",
                  },
                  [
                    _vm._v(
                      "\n          This address may become publicly available.\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAnyProtected,
                        expression: "isAnyProtected",
                      },
                    ],
                    staticClass: "protected-address",
                  },
                  [_vm._v("\n          This address is protected.\n        ")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAnyInvalid,
                        expression: "isAnyInvalid",
                      },
                    ],
                    staticClass: "invalid-icon",
                  },
                  [_vm._v("\n          More information required.\n        ")]
                ),
              ]),
            ]),
          ]
        : _c("ct-centered-spinner", { attrs: { height: "260px" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }